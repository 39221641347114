import { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { noop } from 'lodash'
import QueryString from 'qs'
import usePagination, { UsePaginationReturn } from '../../hooks/usePagination'
import {
  SearchQueryItemsReturn,
  useSearchFilters,
  useSearchQuery,
} from '../../providers/ApolloProvider/GlassOnion/hooks'
import {
  CATEGORY_PARAM,
  CONTENT_TYPE_PARAM,
  ContentType,
  ProfileSocialAccountType,
  DIFFICULTY_PARAM,
  IS_PAPER_PARAM,
  PAGE_PARAM,
  QUERY_PARAM,
  SORT_PARAM,
  SortOption,
  YEARS_PARAM,
  defaultFilterOptions,
  sortFilterOptions,
  SOCIAL_MEDIA_PARAM,
  ALL_CONTENT_FILTER,
} from './GenericSearchContext.constants'

export type GenericSearchContextType = {
  /* Modal Controls */
  isFilterModalOpen: boolean
  setIsFilterModalOpen: (value: boolean) => void
  /* Query Search */
  query: string
  handleQuerySubmit: (value: string) => void
  clearQuery: () => void
  /* Content Types */
  contentType: ContentType | null
  handleContentTypeChange: (contentType: ContentType | null) => void
  /* Categories */
  categories: string[]
  categoryCount: number
  toggleCategory: (category: string) => void
  clearCategories: () => void
  /* Difficulties */
  difficulties: string[]
  difficultyCount: number
  selectDifficulties: (difficulty: string) => void
  clearDifficulties: () => void
  /* Social Media */
  socialMedia: ProfileSocialAccountType[]
  socialMediaCount: number
  toggleSocialMedia: (socialMedia: ProfileSocialAccountType) => void
  clearSocialMedia: () => void
  /* Years of Publication */
  years: number[]
  yearCount: number
  toggleYear: (year: number) => void
  clearYears: () => void
  /* Based on Papers */
  basedOnPapers: boolean
  papersCount: number
  toggleBasedOnPapers: () => void
  clearBasedOnPapers: () => void
  /* Sort */
  sort: SortOption
  setSort: (value: SortOption) => void
  /* Count */
  totalFiltersCount: number
  /* Clear All Filters */
  clearAllFilters?: () => void
  /* Filter Options */
  isLoadingFilterData: boolean
  filterDataErrorMessage: string | null
  categoryFilters: string[]
  difficultyFilters: string[]
  socialMediaFilters: ProfileSocialAccountType[]
  yearFilters: number[]
  basedOnPapersAvailable: boolean
  /* Pagination */
  pagination: UsePaginationReturn
  /* Search Results */
  searchResults: {
    data?: SearchQueryItemsReturn
    loading: boolean
    error: ApolloError | undefined
  }
}

const defaultContext: GenericSearchContextType = {
  isFilterModalOpen: false,
  setIsFilterModalOpen: noop,
  query: '',
  handleQuerySubmit: noop,
  clearQuery: noop,
  contentType: null,
  handleContentTypeChange: noop,
  categories: [],
  categoryCount: 0,
  difficulties: [],
  difficultyCount: 0,
  selectDifficulties: noop,
  clearDifficulties: noop,
  toggleCategory: noop,
  clearCategories: noop,
  socialMedia: [],
  socialMediaCount: 0,
  toggleSocialMedia: noop,
  clearSocialMedia: noop,
  years: [],
  yearCount: 0,
  toggleYear: noop,
  clearYears: noop,
  basedOnPapers: false,
  papersCount: 0,
  toggleBasedOnPapers: noop,
  sort: sortFilterOptions[0],
  setSort: noop,
  clearBasedOnPapers: noop,
  totalFiltersCount: 0,
  clearAllFilters: noop,
  isLoadingFilterData: false,
  filterDataErrorMessage: null,
  pagination: {
    currentPage: 1,
    handleNextPage: noop,
    handlePageInput: noop,
    handlePreviousPage: noop,
    hasNext: false,
    hasPrevious: false,
    offset: 0,
    totalPages: 1,
  },
  ...defaultFilterOptions,
  searchResults: {
    data: undefined,
    loading: false,
    error: undefined,
  },
  socialMediaFilters: [],
}

const GenericSearchContext =
  createContext<GenericSearchContextType>(defaultContext)

export const GenericSearchContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  /* Modal Controls */
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  /* Filter Data */
  const initialParams = QueryString.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  const [contentType, setContentType] = useState<ContentType | null>(
    (initialParams.contentType as ContentType) || null
  )

  const {
    data: filterData,
    loading: isLoadingFilterData,
    error: filterDataError,
  } = useSearchFilters(contentType)

  /* State --------------------- */

  const [query, setQuery] = useState((initialParams.q as string) || '')
  const [categories, setCategories] = useState<string[]>(
    initialParams.categories
      ? (initialParams.categories as string).split(',')
      : []
  )
  const [years, setYears] = useState<number[]>(
    initialParams.years
      ? (initialParams.years as string).split(',').map((y) => parseInt(y))
      : []
  )
  const [basedOnPapers, setBasedOnPapers] = useState(
    initialParams.paper === 'true' && contentType === ContentType.DEMO
      ? true
      : false
  )
  const [difficulties, setDifficulties] = useState<string[]>(
    initialParams.difficulties && contentType === ContentType.CHALLENGE
      ? (initialParams.difficulties as string).split(',')
      : []
  )
  const [socialMedia, setSocialMedia] = useState<ProfileSocialAccountType[]>(
    initialParams.socialMedia
      ? (initialParams.socialMedia
          .toString()
          .split(',') as ProfileSocialAccountType[])
      : []
  )
  const [sort, setSort] = useState<SortOption>(
    sortFilterOptions.find((s) => s.param === (initialParams.sort as string)) ||
      sortFilterOptions[0]
  )
  const [initialPage] = useState(
    initialParams.page ? parseInt(initialParams.page as string) : 1
  )

  /* Handlers ------------------ */

  /* Query Handlers */
  const handleQuerySubmit = (value: string) => {
    setQuery(value)
  }
  const clearQuery = () => {
    setQuery('')
  }

  const handleContentTypeChange = (contentType: ContentType | null) => {
    setContentType(contentType)
    clearAllFilters()
  }

  /* Categories Handlers */
  const toggleCategory = (category: string) => {
    setCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    )
  }
  const clearCategories = () => setCategories([])

  /* Years Handlers */
  const toggleYear = (year: number) => {
    setYears((prev) =>
      prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
    )
  }
  const clearYears = () => setYears([])

  /* Based on Papers Handlers */
  const toggleBasedOnPapers = () => setBasedOnPapers((prev) => !prev)
  const clearBasedOnPapers = () => setBasedOnPapers(false)

  /* Difficulty Handlers */
  const selectDifficulties = (difficulty: string) => {
    setDifficulties((prev) =>
      prev.includes(difficulty)
        ? prev.filter((d) => d !== difficulty)
        : [...prev, difficulty]
    )
  }
  const clearDifficulties = () => setDifficulties([])

  /* Social Media Handlers */
  const toggleSocialMedia = (socialMedia: ProfileSocialAccountType) => {
    setSocialMedia((prev) =>
      prev.includes(socialMedia)
        ? prev.filter((s) => s !== socialMedia)
        : [...prev, socialMedia]
    )
  }
  const clearSocialMedia = () => setSocialMedia([])

  /* Filter Options ---------------- */
  const {
    categoryFilters,
    yearFilters,
    basedOnPapersAvailable,
    difficultyFilters,
  } = useMemo(() => {
    return !isLoadingFilterData &&
      !filterDataError &&
      filterData?.searchFilterOptions
      ? {
          /* Categories */
          categoryFilters:
            contentType === ContentType.DEMO
              ? filterData.searchFilterOptions.demo.categories
              : contentType === ContentType.BLOG
              ? filterData?.searchFilterOptions?.blog.categories
              : contentType === ContentType.CHALLENGE
              ? filterData?.searchFilterOptions?.challenge.categories
              : [],
          /* Years */
          yearFilters: filterData?.searchFilterOptions?.yearsOfPublication,
          /* Based on Papers (Demos only) */
          basedOnPapersAvailable: contentType === ContentType.DEMO,
          /* Difficulties (Challenges only) */
          difficultyFilters:
            filterData?.searchFilterOptions?.challenge.difficulties,
        }
      : defaultFilterOptions
  }, [filterData, isLoadingFilterData, filterDataError, contentType])

  const socialMediaFilters = useMemo(() => {
    return !isLoadingFilterData &&
      !filterDataError &&
      filterData?.searchFilterOptions
      ? filterData.searchFilterOptions.profile.socialAccountTypes
      : []
  }, [filterData, isLoadingFilterData, filterDataError])

  /* Counts --------------- */
  const {
    totalFiltersCount,
    categoryCount,
    yearCount,
    papersCount,
    difficultyCount,
    socialMediaCount,
  } = useMemo(() => {
    const categoryCount = categories.length
    const yearCount = years.length
    const papersCount = basedOnPapers ? 1 : 0
    const difficultyCount = difficulties.length
    const socialMediaCount = socialMedia.length

    return {
      totalFiltersCount:
        categoryCount +
        yearCount +
        papersCount +
        difficultyCount +
        socialMediaCount,
      categoryCount,
      yearCount,
      papersCount,
      difficultyCount,
      socialMediaCount,
    }
  }, [categories, years, basedOnPapers, difficulties, socialMedia])

  /* Clear All Filters */
  const clearAllFilters = () => {
    clearCategories()
    clearYears()
    clearBasedOnPapers()
    clearDifficulties()
    clearSocialMedia()
  }

  /* Pagination */
  const LIMIT = 16
  const [total, setTotal] = useState(0)
  const pagination = usePagination({
    limit: LIMIT,
    totalItems: total,
    initialPage,
  })

  /* Search Query
  - Implementation note: In the future we may need to allow content types to have multiple values
    * the current implementation only allows for a single value
  */
  const searchResults = useSearchQuery({
    query,
    pageOffset: pagination.offset,
    pageLimit: LIMIT,
    years: years || [],
    specificFilters:
      contentType === ContentType.DEMO
        ? { demoFilters: { categories, basedOnPapers } }
        : contentType === ContentType.BLOG
        ? { blogFilters: { categories } }
        : contentType === ContentType.CHALLENGE
        ? { challengeFilters: { categories, difficulties } }
        : contentType === ContentType.PROFILE
        ? { profileFilters: { socialAccountTypes: socialMedia } }
        : null,
    contentTypes: contentType ? [contentType] : ALL_CONTENT_FILTER, // Default to all content types available
    sorts: sort.value === '' ? null : sort.value,
  })

  /* Effects ----------------- */
  useEffect(() => {
    /* Updates total when available */
    if (searchResults?.data?.search?.totalItems) {
      setTotal(searchResults?.data?.search?.totalItems)
    }
  }, [searchResults?.data?.search?.totalItems])

  /* Sanitization of invalid values */
  useEffect(() => {
    if (isLoadingFilterData || filterDataError) return
    if (categories.length > 0 && categoryFilters?.length > 0) {
      const validCategories = categories.filter((c) =>
        categoryFilters.map((cat) => cat.toLowerCase()).includes(c)
      )
      setCategories(validCategories)
    }
    if (years.length > 0) {
      const validYears = years.filter((y) => yearFilters.includes(y))
      setYears(validYears)
    }
    if (difficultyFilters.length > 0 && difficulties.length > 0) {
      const validDifficulties = difficulties.filter((d) =>
        difficultyFilters.includes(d)
      )
      setDifficulties(validDifficulties)
    }
    if (socialMediaFilters.length > 0 && socialMedia.length > 0) {
      const validSocialMedia = socialMedia.filter((s) =>
        socialMediaFilters.includes(s)
      )
      setSocialMedia(validSocialMedia)
    }
  }, [isLoadingFilterData, filterDataError])

  /*  Update the URL with the current state values */
  useEffect(() => {
    const params = {
      [QUERY_PARAM]: query || null,
      [CONTENT_TYPE_PARAM]:
        contentType === ContentType.BLOG
          ? ContentType.BLOG
          : contentType === ContentType.DEMO
          ? ContentType.DEMO
          : contentType === ContentType.CHALLENGE
          ? ContentType.CHALLENGE
          : contentType === ContentType.PROFILE
          ? ContentType.PROFILE
          : null,
      [CATEGORY_PARAM]: categories?.length > 0 ? categories.join(',') : null,
      [YEARS_PARAM]: years?.length > 0 ? years.join(',') : null,
      [IS_PAPER_PARAM]: basedOnPapers ? true : null,
      [PAGE_PARAM]: pagination.currentPage > 1 ? pagination.currentPage : null,
      [SORT_PARAM]: sort.param || null,
      [DIFFICULTY_PARAM]:
        difficulties?.length > 0 ? difficulties.join(',') : null,
      [SOCIAL_MEDIA_PARAM]:
        socialMedia.length && socialMedia.length > 0
          ? socialMedia.join(',')
          : null,
    }
    const newUrl = QueryString.stringify(params, {
      addQueryPrefix: true,
      skipNulls: true,
      strictNullHandling: true,
      arrayFormat: 'comma',
    })
    if (Object.values(params).every((v) => v === null)) {
      window.history.replaceState({}, '', window.location.pathname)
      return
    }
    window.history.replaceState({}, '', newUrl)
  }, [query, contentType, categories, years, basedOnPapers, pagination, sort])

  /* Memoized Context Provider */
  const contextProviderValue = useMemo(
    () => ({
      isFilterModalOpen,
      setIsFilterModalOpen,
      query,
      handleQuerySubmit,
      clearQuery,
      contentType,
      handleContentTypeChange,
      categories,
      categoryCount,
      toggleCategory,
      clearCategories,
      years,
      yearCount,
      toggleYear,
      clearYears,
      basedOnPapers,
      papersCount,
      toggleBasedOnPapers,
      sort,
      setSort,
      clearBasedOnPapers,
      totalFiltersCount,
      clearAllFilters,
      isLoadingFilterData,
      filterDataErrorMessage: filterDataError?.message || null,
      categoryFilters,
      yearFilters,
      basedOnPapersAvailable,
      pagination,
      searchResults,
      difficulties,
      difficultyCount,
      selectDifficulties,
      clearDifficulties,
      difficultyFilters,
      socialMedia,
      socialMediaCount,
      toggleSocialMedia,
      clearSocialMedia,
      socialMediaFilters,
    }),
    [
      isFilterModalOpen,
      setIsFilterModalOpen,
      query,
      handleQuerySubmit,
      clearQuery,
      contentType,
      handleContentTypeChange,
      categories,
      categoryCount,
      toggleCategory,
      clearCategories,
      years,
      yearCount,
      toggleYear,
      clearYears,
      basedOnPapers,
      papersCount,
      toggleBasedOnPapers,
      sort,
      setSort,
      clearBasedOnPapers,
      totalFiltersCount,
      clearAllFilters,
      isLoadingFilterData,
      filterDataError,
      categoryFilters,
      yearFilters,
      basedOnPapersAvailable,
      pagination,
      searchResults,
      difficulties,
      difficultyCount,
      selectDifficulties,
      clearDifficulties,
      difficultyFilters,
      socialMedia,
      socialMediaCount,
      toggleSocialMedia,
      clearSocialMedia,
      socialMediaFilters,
    ]
  )

  return (
    <GenericSearchContext.Provider value={contextProviderValue}>
      {children}
    </GenericSearchContext.Provider>
  )
}

/**
 * Custom hook to use the GenericSearchContext
 */
export const useGenericSearchContext = () => {
  const context = useContext(GenericSearchContext)
  if (!context) {
    throw new Error(
      'useGenericSearchContext must be used within a GenericSearchContextProvider'
    )
  }
  return context
}
